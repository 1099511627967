import * as actions from '../actions';
import { createReducer } from './util/createReducer';
import kleinbus_frei from '../images/minivan/kleinbus_kompakt.png';
import kleinbus1 from '../images/minivan/kleinbus_front.jpg';
import kleinbus2 from '../images/minivan/kleinbus_seite.jpg';
import kleinbus3 from '../images/minivan/kleinbus_hinten.jpg';
import kleinbus5 from '../images/minivan/kleinbus_cockpit.jpg';
import mittel_frei from '../images/kleinbus-mittel/kleinbus_mittel.png';
import mittel1 from '../images/kleinbus-mittel/mittellanger_bus_front.jpg';
import mittel2 from '../images/kleinbus-mittel/mittellanger_bus_seite.jpg';
import mittel3 from '../images/kleinbus-mittel/mittellanger_bus_hinten.jpg';
import mittel5 from '../images/kleinbus-mittel/mittellanger_bus_vordersitze.jpg';
import mittel6 from '../images/kleinbus-mittel/mittellanger_bus_cockpit.jpg';
import lang_frei from '../images/kleinbus-lang/kleinbus_lang.png';
import lang1 from '../images/kleinbus-lang/langer_bus_front.jpg';
import lang2 from '../images/kleinbus-lang/langer_bus_seite.jpg';
import lang3 from '../images/kleinbus-lang/langer_bus_hinten.jpg';
import lang4 from '../images/kleinbus-lang/langer_bus_gadets.jpg';
import lang5 from '../images/kleinbus-lang/langer_bus_cockpit.jpg';
import lang6 from '../images/kleinbus-lang/langer_bus_screen.jpg';
import lang9 from '../images/kleinbus-lang/langer_bus_front_weiß.jpg';
import lang7 from '../images/kleinbus-lang/langer_bus_seite_weiß.jpg';
import lang10 from '../images/kleinbus-lang/langer_bus_seite2_weiß.jpg';
import lang11 from '../images/kleinbus-lang/langer_bus_cockpit_weiß.jpg';
import lang8 from '../images/kleinbus-lang/langer_bus_hinten_weiß.jpg';
import manLang1 from '../images/man-lang/man_lang_front.jpg';
import manLang2 from '../images/man-lang/man_lang_seite.jpg';
import manLang3 from '../images/man-lang/man_lang_hinten.jpg';
import manLang4 from '../images/man-lang/man_lang_cockpit.jpg';
import manLangFrei from '../images/man-lang/man-lang-freisteller.png';
import manMittel1 from '../images/man-mittel/man_mittel_front.jpg';
import manMittel2 from '../images/man-mittel/man_mittel_seite.jpg';
import manMittel3 from '../images/man-mittel/man_mittel_seite2.jpg';
import manMittel4 from '../images/man-mittel/man_mittel_hinten.jpg';
import manMittel5 from '../images/man-mittel/man_mittel_cockpit.jpg';
import manMittelFrei from '../images/man-mittel/man-mittel-freisteller.png';

const initialState = {
  loading: false,
  store: {
    56: {
      id: 56,
      price: 0,
      priceBus: 0,
      freeKm: 0,
      versicherung: 0,
      priceZKm: 0,
      days: 0,
      img: kleinbus_frei,
      imgs: [kleinbus1, kleinbus2, kleinbus3, kleinbus5],
      name: 'VW T5, 2/3/3 Sitze, Diesel',
      persons: '3-8',
      storage: 830,
      description:
        'Mit dem Kleinbus bis du für kurze Trips in der Stadt perfekt ausgestattet. Mit den maximal 8 Sitzen transportiert dieser Bus fast so viele Personen wie seine großen Brüder.',
      height: '1,99m',
      length: '5,39m',
      fuel: 'Diesel',
      gear: 'Manuell 5-Gang',
      radio: 'USB Radio',
    },
    58: {
      id: 58,
      price: 0,
      priceBus: 0,
      freeKm: 0,
      versicherung: 0,
      priceZKm: 0,
      days: 0,
      img: mittel_frei,
      imgs: [mittel1, mittel2, mittel3, mittel5, mittel6, lang4],
      name: 'VW Crafter, 3/3/3 Sitze, Diesel',
      persons: 9,
      storage: 1100,
      description:
        'Dieser 9-Sitzer eignet sich für jede Strecke. Mit 9 Sitzplätzen und einem großen abgetrennten Laderaum bringt er nicht nur deine ganze Reisegruppe, sondern auch viel Gepäck ans Ziel.',
      height: '2,80m',
      length: '6,00m',
      fuel: 'Diesel',
      gear: 'Manuell 6-Gang',
      parkingCamera: true,
      radio: 'Bluetooth Radio',
    },
    54: {
      id: 54,
      price: 0,
      priceBus: 0,
      freeKm: 0,
      versicherung: 0,
      priceZKm: 0,
      days: 0,
      img: lang_frei,
      imgs: [
        lang1,
        lang2,
        lang3,
        lang4,
        lang5,
        lang6,
        lang9,
        lang7,
        lang10,
        lang11,
        lang8,
      ],
      name: 'VW Crafter, 2/2/2/3 Sitze, Diesel',
      persons: 9,
      storage: 1000,
      description:
        'Unser langer Bus bietet den höchsten Komfort! Viel Beinfreiheit, großer Stauraum und dazu modernste Entertainment-Technik lassen auch die längste Reise wie im Fluge vergehen.',
      height: '2,80m',
      length: '7,00m',
      fuel: 'Diesel',
      gear: 'Manuell 6-Gang',
      parkingCamera: true,
      radio: 'Bluetooth Radio',
    },
    64: {
      id: 64,
      price: 0,
      priceBus: 0,
      freeKm: 0,
      versicherung: 0,
      priceZKm: 0,
      days: 0,
      img: manLangFrei,
      imgs: [manMittel1, manMittel2, manMittel3, manMittel4, manMittel5],
      name: 'MAN TGE, 2/3/3 Sitze, Diesel, Ad Blue',
      persons: '2-8',
      storage: 1050,
      description:
        'Der mittellange MAN Bus bietet viel Flexibilität und Komfort. Er hat bis zu 8 Einzelsitze, die auf einer Airline Schiene nach belieben verschoben werden können. Dazu 360° Rückfahr-Kamera und Automatik-Getriebe.',
      height: '2,60m',
      length: '5,95m',
      fuel: 'Diesel, AdBlue',
      gear: 'Automatik 8-Gang',
      radio: 'USB Radio',
    },
    63: {
      id: 63,
      price: 0,
      priceBus: 0,
      freeKm: 0,
      versicherung: 0,
      priceZKm: 0,
      days: 0,
      img: manMittelFrei,
      imgs: [manLang1, manLang2, manLang3, manLang4],
      name: 'MAN TGE, 2/2/2/3 Sitze, Diesel, Ad Blue',
      persons: '2-9',
      storage: 1050,
      description:
        'Der lange MAN Bus ist ein Stauraum-Wunder. Durch die flexiblen Sitze können selbst Gegenstände mit mehreren Metern Länge transportiert werden. Dazu 360° Rückfahr-Kamera und Automatik-Getriebe.',
      height: '2,70m',
      length: '6,80m',
      fuel: 'Diesel, AdBlue',
      gear: 'Automatik 8-Gang',
      radio: 'USB Radio',
    },
  },
};
export default createReducer(initialState, {
  [actions.calculatePrice]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [actions.calculatePriceSuccess]: (
    state,
    { payload: { busId, priceData } }
  ) => {
    return {
      ...state,
      loading: false,
      store: {
        ...state.store,
        [busId]: { ...state.store[busId], ...priceData },
      },
    };
  },
});
