import React from 'react';
import Mountain from '../../images/mountain.jpg';
import { H2, LayoutContent, LayoutSection } from '../../ui';
import Stage from '../../ui/Stage/Stage';
import styled from 'styled-components';
import { breakpoints } from '../../ui/theme/breakpoints';
import { history } from '../../config/store';
import Button from '../../ui/Button/Button';
import Stepper from '../../ui/Stepper/Stepper';
import ExtraOption from './ExtraOption';
import {
  Adapter,
  ChevronR,
  Kindersitz,
  Navi,
  Rampe,
  Rollstuhl,
  Snow,
  Versicherung,
} from '../../ui/Icon';
import BookingBox from '../common/BookingBox';
import t5 from '../../images/minivan/kleinbus_kompakt.png';
import * as actions from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    flex-wrap: nowrap;
  }
`;

const ExtrasWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 2rem;
  flex-shrink: 1;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    width: 75%;
    padding-right: 2rem;
  }

  > label {
    width: calc(50% - 1rem);
    margin-bottom: 2rem;
    flex-grow: 1;
    margin-left: 1rem;

    @media screen and (max-width: ${breakpoints.minTablet}) {
      width: 90%;
      margin: 0 auto 2rem;
    }
  }
`;

const CartWrapper = styled.div`
  width: 25%;
  min-width: 28rem;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 90%;
    margin: 0 auto 2rem;
  }
`;

const SubmitWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export function ExtrasPage() {
  const booking = useSelector((store) => store.Booking.store);
  const busses = useSelector((store) => store.Bus.store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      !booking.bus ||
      !busses[booking.bus].priceBus ||
      !booking.startDate ||
      !booking.endDate
    ) {
      history.push('/');
    }
    dispatch(actions.calculatePrice(booking));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, dispatch]);

  const onChange = ({ fieldName, value }) => {
    dispatch(actions.setBookingValue(fieldName, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push('/personendaten');
  };

  return (
    <div>
      <Stage
        imgSrc={Mountain}
        alt={'Gruppenbus'}
        slogan={'Buche Extras dazu'}
        small
      />
      <LayoutContent>
        <LayoutSection>
          <Stepper step={2} />
          <H2 center>Wähle gewünschte Extras</H2>
          <Form onSubmit={handleSubmit}>
            <ExtrasWrapper>
              <ExtraOption
                Icon={Versicherung}
                title="Versicherung"
                name="versicherung"
                price="15,00 € / Tag"
                description="Vollkaskoschutz von 2500,- auf 500,- EUR Selbstbeteiligung reduzieren / Teilkasko 500,- EUR"
                onChange={(value) =>
                  onChange({ fieldName: 'versicherung', value })
                }
              />

              <ExtraOption
                Icon={Snow}
                title="Schneeketten"
                name="schneeketten"
                price="25,00 €"
                description="1 Paar Ketten für Antriebsachse. Die Ketten sind leicht zu montieren."
                onChange={(value) =>
                  onChange({ fieldName: 'schneeketten', value })
                }
              />

              <ExtraOption
                Icon={Navi}
                title="Navi"
                name="navi"
                price="10,00 €"
                description="Ein tragbares Navigationsgerät für die Fahrt ohne Umweg."
                onChange={(value) => onChange({ fieldName: 'navi', value })}
              />

              <ExtraOption
                Icon={Adapter}
                title="220V Steckdose"
                name="wechselrichter"
                price="10,00 €"
                description="Der Adapter verwandelt die 12V Anschlüsse (Zigarettenanzünder) in eine 220V Steckdose."
                onChange={(value) =>
                  onChange({ fieldName: 'wechselrichter', value })
                }
              />
              <ExtraOption
                Icon={Kindersitz}
                title="Kindersitz"
                name="kindersitz"
                price="15,00 €"
                description="Ein Kindersitz für Kinder im Alter von 2 - 7 Jahren."
                onChange={(value) =>
                  onChange({ fieldName: 'kindersitz', value })
                }
              />
              {(booking.bus === 63 || booking.bus === 64) && (
                <>
                  <ExtraOption
                    Icon={Rampe}
                    title="Laderampe / Rollstuhlrampe"
                    name="laderampe"
                    price="50,00 €"
                    description="Eine mobile Rampe zum Beladen oder für Rollstühle. Nur bei MAN Fahrzeugen buchbar."
                    onChange={(value) =>
                      onChange({ fieldName: 'laderampe', value })
                    }
                  />
                  <ExtraOption
                    Icon={Rollstuhl}
                    title="Rollstuhlhaltesystem"
                    name="Rollstuhlhaltesystem"
                    price="15,00 €"
                    description="Für den sicheren Transport von im Rollstuhl Fahrenden. Nur bei MAN Fahrzeugen buchbar."
                    onChange={(value) =>
                      onChange({ fieldName: 'Rollstuhlhaltesystem', value })
                    }
                  />
                </>
              )}
            </ExtrasWrapper>

            <CartWrapper>
              <BookingBox imgSrc={t5} />
              <SubmitWrapper>
                <Button type="submit" big Icon={ChevronR} rounded>
                  Weiter
                </Button>
              </SubmitWrapper>
            </CartWrapper>
          </Form>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
